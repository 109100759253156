import { Component } from '@angular/core';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions-en.component.html',
    styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsEnComponent {
  constructor() {}
}
